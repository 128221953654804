import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import genLabelStyle from './gen-label-style';
import {FeatureUrlFunction} from 'ol/featureloader';
import {FieAnalysisService} from '../analysis/analysis.service';
import {GeoJSON} from 'ol/format';
import {Geometry} from 'ol/geom';
import {HsEventBusService, HsToastService} from 'hslayers-ng';
import {HsUtilsService} from 'hslayers-ng';
import {HttpClient} from '@angular/common/http';
import {OSM, Source, TileArcGISRest, XYZ} from 'ol/source';
import {Tile} from 'ol/layer';
import {TileWMS, Vector as VectorSource} from 'ol/source';
import {environment} from 'environments/environment';
import {meteoblueLayers} from './meteo-layers';
import {trackSld} from './track-sld';
import {vegetationLayersLesprojekt} from './vegetation-layers';

const labelStyle = genLabelStyle;
const assetsPath = (<any>window).assetsPath
  ? (<any>window).assetsPath
  : window.location.hostname.indexOf('local.groundwater') > -1
  ? `assets/`
  : 'static/assets/';

export const infrared = new Tile({
  properties: {
    title: 'Infrared satellite map (Vilcini)',
    from_composition: true,
    dimensions: {
      time: {
        value: '2020-11-20',
        name: 'time',
        values: ['2020-11-20'],
      },
    },
    editor: {editable: false},
    path: 'Vegetation indexes and satellite imagery',
  },
  source: new XYZ({
    attributions:
      '&copy; <a href="http://www.baltsat.lv/">Baltic Satellite Service</a>, <a href="https://www.esa.int/">European Space Agency - ESA</a>',
    url: 'https://wms.forestradar.com/tiles-v1/fie-xNFwHfJdIR1dCtA7kJ1K8g/{time}-CIR/{z}/{x}/{y}.png',
  }),
  maxZoom: 18,
  visible: false,
  opacity: 1,
});
export const default_layers = [
  new Tile({
    properties: {
      title: 'OpenStreetMap',
      base: true,
      editor: {editable: false},
      removable: false,
    },
    source: new OSM(),
    visible: false,
  }),
  new Tile({
    properties: {
      title: 'Ortophoto',
      base: true,
      removable: false,
    },
    source: new TileWMS({
      url: 'https://geoportal.cuzk.cz/WMS_ORTOFOTO_PUB/WMService.aspx',
      params: {
        LAYERS: 'GR_ORTFOTORGB',
        INFO_FORMAT: undefined,
        FORMAT: 'image/jpeg',
      },
      crossOrigin: 'anonymous',
    }),
    visible: true,
  }),
  new Tile({
    properties: {
      title: 'Katastr nemovitostí',
      removable: false,
    },
    source: new TileWMS({
      url: 'https://services.cuzk.cz/wms/local-km-wms.asp',
      params: {
        LAYERS: 'KN',
        INFO_FORMAT: undefined,
        FORMAT: 'image/png',
      },
      crossOrigin: 'anonymous',
    }),
    visible: true,
  }),
  new Tile({
    properties: {
      title: 'Základní mapa 1:10 000',
      base: true,
      removable: false,
    },
    source: new TileWMS({
      url: 'https://geoportal.cuzk.cz/WMS_ZM10_PUB/service.svc/get',
      params: {
        LAYERS: 'GR_ZM10',
        INFO_FORMAT: undefined,
        FORMAT: 'image/jpeg',
      },
      crossOrigin: 'anonymous',
    }),
    visible: true,
  }),
  ...environment.layers,
  ...vegetationLayersLesprojekt,
  ...[
    /* new Tile({
      properties: {
        title: 'Unsupervised classification - 40 classes',
        path: 'Other',
        inlineLegend: true,
      },
      source: new TileWMS({
        url: 'https://gis.lesprojekt.cz/qgisserver/cgi-bin/qgis_mapserv.fcgi?map=/opt/RVI4S1/latvia/komp_latvia.qgs',
        params: {
          LAYERS: '40cl_km_15it_rd_md_c',
          INFO_FORMAT: undefined,
          FORMAT: 'image/png; mode=8bit',
        },
        crossOrigin: 'anonymous',
      }),
      visible: false,
      opacity: 0.9,
    }), */
  ],
  ...meteoblueLayers,
];

export async function createTrackLayers(group_name: string) {
  const response = await fetch(
    `https://sensor.lesprojekt.cz/senslogOT2/rest/track/dates?group_name=${group_name}`
  );
  const data = await response.json();
  return data.map((unit) => {
    const lastDate = unit.dates[unit.dates.length - 1];

    const dimensions = {
      date: {
        label: 'Date',
        value: lastDate,
        values: unit.dates,
      },
    };

    const source = new VectorSource({
      format: new GeoJSON(),
      url: function (extent, resolution, projection) {
        const dimensions = source.get('dimensions');
        return `https://sensor.lesprojekt.cz/senslogOT2/rest/track/points?unit_id=${source.get(
          'unit_id'
        )}&from_time=${dimensions.date.value}`;
      },
      loader: function (extent, resolution, projection, success, failure) {
        const src = this as VectorSource<Geometry>;
        fetch(
          (src.getUrl() as FeatureUrlFunction)(extent, resolution, projection)
        )
          .then((response) => response.json())
          .then((data) => {
            const features = src.getFormat().readFeatures(data, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857',
            }) as Feature<Geometry>[];
            src.addFeatures(features);
            success(features);
          });
      },
    });

    source.set('dimensions', dimensions);
    source.set('unit_id', unit.unit_id);

    return new VectorLayer({
      properties: {
        title: 'Track ' + unit.unit_description,
        path: 'Unit tracks',
        dimensions,
        sld: trackSld,
      },
      source,
    });
  });
}

export function setupLayers(
  HsLanguageService,
  HsPermalinkUrlService,
  FieAnalysisService: FieAnalysisService,
  HsToastService: HsToastService
) {}

export async function updateDimensionOptions(
  layers: any[],
  httpClient: HttpClient,
  hsUtils: HsUtilsService,
  hsEventBusService: HsEventBusService
) {
  const response: any = await httpClient
    .get(
      hsUtils.proxify(
        'https://fr-cdn.ams3.cdn.digitaloceanspaces.com/zs-fie-xNFwHfJdIR1dCtA7kJ1K8g/tile-data.json'
      )
    )
    .toPromise();
  for (const layer of layers) {
    const dimensions = layer.get('dimensions');
    if (dimensions) {
      layer.get('dimensions').time.values = response.img_dates;
    }
    hsEventBusService.layerDimensionDefinitionChanges.next({
      layer,
      app: 'default',
    });
  }
}
