import { BrowserModule } from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import { NgModule } from '@angular/core';
import { FieAnalysisPanelComponent } from './analysis.component';
import {FieAnalysisService} from './analysis.service';
import {InfoComponent} from './info.component';
import { HsPanelHelpersModule } from 'hslayers-ng';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    FieAnalysisPanelComponent, InfoComponent
  ],
  imports: [
    BrowserModule, FormsModule, NgbModule,
    HsPanelHelpersModule
  ],
  exports:[FieAnalysisPanelComponent],
  providers: [FieAnalysisService],
  entryComponents: [FieAnalysisPanelComponent, InfoComponent]
})
export class AnalysisModule { }

