export const analysesLvTranslations = {
    'ANALYSIS_DESCRIPTIONS': {
        meteogram_agro_hd: `<p>meteoblue agro meteogramma parāda laika apstākļu attīstību ar gaisa temperatūras, vēja ātruma un virziena diagrammām uz zemes, kā arī nokrišņus, mākoņus, ieteicamo laiku augu smidzināšanai iztvaikošanas datus. Dati attiecās uz modeļa režģa šūnas prognozēto apgabalu, neņemot vērā izvēlētās vietas īpašos apstākļus (piemēram, augstuma atšķirības).</p>

        <p>Visu laikapstākļu datu attēlošana notiek pēc vietējā laika.</p>
        
        <p>Temperatūru parāda krāsu līkne ar stundas intervālu. Laikapstākļu simboli atrodas tajā pašā diagrammā, lai sniegtu īsu kopsavilkumu.</p>
        
        <p>Nokrišņi tiek parādīti ar tumši zilām joslām, kas attēlo kopējos lietus datus, un ar gaiši zilām joslām - lietusgāzes. Nokrišņu varbūtību (%) attēlo rozā līkne.</p>
        
        <p>Mākoņu dati tiek parādīti kā funkcija no augstuma (asl) un kopējā mākoņu pārklājuma. Brūnā josla parāda augstumu virs jūras līmeņa.</p>
        
        <p>Smidzināšanas logs palīdz noteikt piemērotu laika periodu kultūraugu aizsardzības līdzekļu lietošanai, attēlojot kā piemērotu laika periodu zaļā krāsā, mazāk piemērotu dzeltenā krāsā un nepiemērotu – sarkanā krāsā. Apstākļi tiek aprēķināti pēc vēja, nokrišņiem, temperatūras un mitruma datiem. Sistēmas sniegtais ieteikums jāpārbauda tieši pirms smidzināšanas darbu veikšanas, ņemot vērā pašreizējos faktiskos laikapstākļus.</p>
        
        <p>Nākamā diagramma parāda iztvaikošanas un transpirācijas (iztvaikošana caur lapām) līdzsvaru mm un relatīvo mitrumu (%) apgabalā.</p>
        
        <p>Vēja diagrammā stundas intervālos tiek parādīts vēja virziens ar vēja simboliem (parāda virzienu un ātrumu), kā arī vēja ātrums (gaiši oranža līkne) un vēja brāzmas (izceltā līkne).</p>
        
        <p>Papildus informāciju, lūdzu, skatiet te: <a href="https://content.meteoblue.com/en/content/view/full/1625" target="_blank">https://content.meteoblue.com/en/content/view/full/1625</a></p>
        `,
        meteogram_14day: `<p>14 dienu meteogramma parāda laika prognozi nākamajai nedēļai ar laikapstākļu simboliem, maksimālās, vidējās un minimālās temperatūras diapazonu, nokrišņu daudzumu, kā arī nokrišņu varbūtību katrai dienai. Diapazons starp iespējamām minimālajām un maksimālajām vērtībām parāda iespējamo laikaapstākļu attīstību.</p>

        <p>Laikapstākļu simboli un dienas temperatūras maksimums un minimums tiek parādīts zem datumiem (definēti pēc vietējā laika), lai sniegtu aptuvenu pārskatu par gaidāmajiem laikapstākļiem. Tie tiek izvēlēti, pamatojoties uz visticamāko katras dienas situāciju. Ja rezultātu atšķirība ir liela (zema konverģence, sk. zemāk), visticamākais, ka situācija atšķirsies no vidējiem prognozētajiem datiem, kas, savukārt, var izraisīt laikapstākļu simbolu un nokrišņu daudzumu neatbilstību, kas parādīts apakšējā sadaļā.</p>
        
        <p>Temperatūras diagramma parāda paredzamo maksimālo un minimālo temperatūru kopā ar to nenoteiktības diapazoniem, kas noteikti, salīdzinot vairākus laikapstākļu modeļus. Izceltā līnija katra diapazona vidū norāda uz visticamāko prognozi, situāciju.</p>
        
        <p>Nokrišņu diagramma parāda ikdienas nokrišņu daudzumu ar to iespējamo minimālo un maksimālo daudzumu diapazonu, kas norādīts ar melnām kļūdu robežām (simboliem). Nokrišņu iespējamība ir norādīta diagrammas apakšējā daļā.</p>
        
        <p>14 dienu meteogramma var būt noderīga, lai paredzētu ārkārtēju laikapstākļu risku. Noderīgi šādos periodos ir sekot līdz ikdienas prognozēm. Ja 14. dienā tiek prognozēti ārkārtas laikapstākļi (sals, ļoti augsta temperatūra, stipri nokrišņi), tad nepieciešmas pievērst uzmanību sekojošiem parametriem:
        Starpībai starp maksimālajām un minimālajām vērtībām: ja atšķirība ir maza, modeļi ir vienisprātis, un notikums, visticamāk, notiks. Ja atšķirība ir liela, notikuma iespējamība ir mazāka.
        Konsekvencei: ja visi laikapstākļu rādītāji norāda uz vienu un to pašu situāciju, tad prognozētais notikums ir ticamāks. Piemēram, ārkārtējs temperatūras kritums kopā ar pieaugošu vēju, mākoņiem, nokrišņiem varētu liecināt par gaidāmo sniegputeni. Ja tikai daži laikapstākļu mainīgie norāda uz to pašu situāciju, piem., tiek prognozēts stiprs lietus, bet temperatūras pazemināšanās nav un mākoņi ir tikai nedaudz, notikums ir mazāk iespējams vai arī tas nebūs ļoti izteikts.</p>
        
        <p>Papildus informāciju, lūdzu, skatiet vietnē: <a href="https://content.meteoblue.com/ru/time-dimensions/forecast/meteograms/14-days" target="_blank">https://content.meteoblue.com/ru/time-dimensions/forecast/meteograms/14-days</a>.</p>
        `,
        pictoprintDayUvRain: `<p>Prognozētās piktogrammas (Pictoprint Day UV Rain) parāda dienas prognozi 7 dienām ar katras dienas temperatūru, vēja ātrumu, vēja virzienu, UV indeksu, nokrišņu daudzumu un stiprāko lietu punktus (rainSPOT). Turklāt tās parāda arī saullēkta un saulrieta laikus.</p>

        <p>rainSPOT rāda nokrišņus 15 km rādiusā ap prognozes vietu. Plašāku informāciju par rainSPOT skatiet vietnē: <a href="https://content.meteoblue.com/en/time-dimensions/forecast/rainspot" target="_blank">https://content.meteoblue.com/en/time-dimensions/forecast/rainspot</a>. </p>
       `,
        meteogram_soiltraffic: `<p>Augsnes noturības meteogramma parāda augsnes spēju atbalstīt transporta līdzekļus, pamatojoties uz ūdens apjomu augsnes virskārtā (0–10 cm). Augsnes noturība ir attēlota ar melnu līniju diagrammā, kas mainās atkarībā no situācijas, t.i. pārvietošanās apstākļiem: labi apstākļi ir attēloti zaļā krāsā, ierobežoti pārvietošanās apstākļi – dzeltenā krāsā, un nav iespēja pārvietoties - sarkanā krāsā.</p>

        <p>Augsnes noturības izmaiņas aprēķina četriem augsnes veidiem - smilts, smilšmāls, dūņas un māls, no kuriem katram ir atšķirīga ūdens noturības spēja un tie atšķirīgi reaģē uz laikapstākļiem.</p>
        
        <p>Aprēķinā tiek izmantota informācija par nokrišņu daudzumu, dienasgaismas stundām, augsnei raksturīgo ūdens noturības spēju un vēl citiem parametriem.</p>
        `,
        meteogram_agroSowing: `<p>Sēšanas meteogramma parāda augsnes temperatūras un nokrišņu attīstību 7 dienām. Tā attēlo kukurūzas, kviešu, miežu, rapšu, kartupeļu un cukurbiešu sēšanas logus. Sēšanas logs ir sadalīts trīs kategorijās: stundu intervālu periodi, kas ir piemēroti (zaļā krāsā), mazāk piemēroti (dzeltenā krāsā) un nepiemēroti (sarkanā krāsā) sēšanai.</p>

        <p>Papildus informāciju, lūdzu, skatiet vietnē <a href="https://content.meteoblue.com/hu/access-options/meteoblue-weather-api/images#Meteogram_AgroSowing" target="_blank">https://content.meteoblue.com/hu/access-options/meteoblue-weather-api/images#Meteogram_AgroSowing</a></p>
        `,
        meteogram_agroSpraying: `<p>Smidzināšanas meteogramma parāda temperatūras un nokrišņu prognozes 7 dienām. Tā attēlo izsmidzināšanas logu. Izsmidzināšanas logs palīdz noteikt piemērotu periodu augu aizsardzības līdzekļu lietošanai, parādot piemērotus (zaļā krāsā), mazāk piemērotus (dzeltenā krāsā) un nepiemērotus (sarkanā krāsā) periodus. Smidzināšanas periodus aprēķina pēc vēja, nokrišņu, temperatūras un mitruma datiem.</p>

        <p>Papildus informāciju, lūdzu, skatiet vietnē: <a href="https://content.meteoblue.com/hu/access-options/meteoblue-weather-api/images#Meteogram_AgroSpraying" target="_blank">https://content.meteoblue.com/hu/access-options/meteoblue-weather-api/images#Meteogram_AgroSpraying</a>.</p>
        `,
        history_frostrisk: `<p>Salnu risks ir aukstuma notikumu iespējamība, kas definēta kā temperatūra, kas zemāka par noteiktu temperatūras slieksni un ilgst noteiktu laiku. Augšējā diagrammā parādīta aukstuma gadījumu iespējamība dienā un nedēļas laikā. Otra diagramma parāda pieļaujamā riska periodus. Ja, piemēram, jūsu pieļaujamais sala notikuma risks bija 20%, tad jūs plānosiet sēšanas periodu laikā, ko parāda 5.grafika attēlotā līnija (pelēkā daļa), kas atbilst 20%, skaitot no apakšas. Trešajā diagrammā parādīti salnu gadījumi, kas bijuši katru gadu pēdējo 30 gadu laikā.</p>

        <p>Papildus informāciju, lūdzu, skatiet vietnē: <a href="https://content.meteoblue.com/hu/access-options/history/risk-assessment" target="_blank">https://content.meteoblue.com/hu/access-options/history/risk-assessment</a>.</p>
        `,
        protected_areas_hint: `<p><a href="#" (click)="activateAoiDraw()">Uzzīmējiet</a> vai iezīmējiet objektus "Interešu apgabali" slānī un pēc tam 'Izpildīt analīzi'</p><p>Analīzes “Aizsargājamās teritorijas” rezultāts ir datu slānis, kas parāda teritorijas, kur lauku mēslošana nav atļauta, jo attālums līdz ūdens resursiem ir neliels. Analīzes aprēķina algoritms ņem vērā lauka ģeometriju, ūdens resursu slāni un nosaka teritorijas, kurās mēslošana nav atļauta.</p>`,
        "ndvi-average-daily-change-hint": `<p><a href="#" (click)="activateAoiDraw()">Uzzīmējiet</a> vai iezīmējiet objektus "Interešu apgabali" slānī un pēc tam 'Izpildīt analīzi' <a href="#" onclick="var el = document.querySelector('.more-ndvi-average-change'); el.style.display=el.style.display=='block' ? 'none' : 'block'">...</a></p>
        <p style="display:none" class="more-ndvi-average-change">Slānis sniedz informāciju par NDVI indeksa vidējām izmaiņām dienā izvēlētajā periodā. Tas var sniegt lauksaimniekam informāciju par dažu pēdējo dienu notikumu vai notikumu rezultātiem, piem. kad bija ļoti karstas un sausas dienas, vai arī pretēji ļoti spēcīgas lietavas vai kāda krusa. Vai arī tas var identificēt vietas, kur nepieciešama iejaukšanās, lai atbalstītu kultūraugu audzēšanu vai ziedēšanu. Izmantojot slāņa precizitāti, tas var identificēt plankumus ar dažiem lauka nelīdzenumiem. Slānis parāda vidējo rādītāja maiņu starp punktiem, kas uzņemti nākamajos attēlos bez mākoņiem - tas ir pašreizējais un tuvākais iepriekšējais bez mākoņiem, bet maksimālais laika attālums starp 2 nemākoņiem attēliem ir 2 nedēļas.</p>`,
        fertilization_hint: `<p><a href="#" (click)="activateAoiDraw()">Uzzīmējiet</a> vai iezīmējiet objektus "Interešu apgabali" slānī un pēc tam 'Izpildīt analīzi'  <a href="#" onclick="var el = document.querySelector('.more-fertilization'); el.style.display=el.style.display=='block' ? 'none' : 'block'">...</a></p>
        <p style="display:none" class="more-fertilization">Pamatojoties uz lauka neviendabīgumu, var aprēķināt mainīgo mēslojuma lietošanas karti, lai efektīvi izplatītu mēslojumu uz lauka. Tā parada apgabalus, kur izmantot lielāku vai mazāku izejvielu daudzumu. Kad lietojuma karte tiek aprēķināta, pamatojoties uz ražas pārvaldības zonu slāni, sezonas laikā ir stabils laiks, pamatojoties uz pašu lietojumu noteikumiem. Ja intervencei ir piemēroti laika apstākļi, var ņemt vērā citas analīzes - augsnes kustīgumu, izsmidzināšanas logu. To var izmantot abiem mēslošanas veidiem - pirms stādīšanas, ar slāpekli. Rezultāts ir lietojuma karte, kurā norādīts ieteicamais mēslojuma daudzums uz kvadrātmetru.</p></p>`,
        click_to_run: 'Nospiediet jebkur uz kartes, lai izpildītu analīzi'
    },
    ANALYSIS_TITLES: {
        'Choose analysis': 'Izvēlieties analīzi',
        'meteogram_agro_hd': '7 dienu agro meteogramma',
        'meteogram_14day': '14 dienu meteogramma',
        'pictoprintDayUvRain': 'Prognozes piktogrammas',
        'meteogram_soiltraffic': 'Augsnes noturības meteogramma',
        'meteogram_agroSowing': 'Sēšanas meteogramma',
        'meteogram_agroSpraying': 'Smidzināšanas meteogramma',
        'history_frostrisk': 'Salnu risks',
        'protected-areas': 'Aizsargājamās zonas',
        'fertilization-map': "Mēslošanas karte",
        'ndvi-average-daily-change': 'Vidējā NDVI izmaiņas pa dienām',
        'crop-weather-monitoring': 'Sējumu laikapstākļu novērojumi'
    },
    ANALYSIS_PARAMS: {
        "fertilizationMethod": "Metode",
        "fertilizerVolume": "Apjoms",
        "add": "Pievienojošā",
        "comp": "Kompensējošā",
        "offset": "Buferzona (m)",
        "crop": "Kultūra",
        "risk": "Risks",
        "years_actual_end": "Pēdējā salīdzināšanas perioda pēdējais gads",
        "years_actual_start": "Pēdējā salīdzināšanas perioda pirmais gads, sākot no 1985. gada",
        "years_clima_end": "Pagājušā salīdzināšanas perioda pēdējais gads",
        "years_clima_start": "Pagājušā salīdzināšanas perioda pirmais gads, sākot no 1985. gada",
        "month_start": "Sākotnējais kultūraugu tipiskās augšanas sezonas mēnesis",
        "month_end": "Augu tipiskais augšanas sezonas pēdējais mēnesis",
        "Drought": "Sausums",
        "Frost": "Sals",
        "Heat wave": "Karstuma vilnis",
        
    },
    ANALYSIS_LEGEND: {
        'Allowed': 'Atļauts',
        'Prohibited': 'Aizliegts',
    },
    ANALYSIS: {
        RunAnalysisOnAreas: 'Izpildīt analīzi objektiem',
        All: 'Visiem',
        Selected: 'Iezīmētajiem',
        Back: 'Atpakaļ uz sarakstu'
    },
    'Run analysis': 'Izpildīt analīzi',
    PANEL_HEADER: { ANALYSIS: 'Analīze' },
    FieldHistoryDataDidntExist: "Lauka vēsture neeksistēja",
    'Analysis failed': 'Analīze neveiksmīga'
}