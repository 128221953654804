export const trackSld = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<StyledLayerDescriptor version="1.0.0" xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd" xmlns="http://www.opengis.net/sld" xmlns:ogc="http://www.opengis.net/ogc" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <NamedLayer>
    <Name/>
    <UserStyle>
      <Name/>
      <Title/>
      <FeatureTypeStyle>
        <Rule>
          <Name>0.00 - 4.50 Teltonika EYE sensor BTSMP1 1</Name>
          <Filter xmlns="http://www.opengis.net/ogc">
            <And>
              <PropertyIsGreaterThanOrEqualTo>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>0</Literal>
              </PropertyIsGreaterThanOrEqualTo>
              <PropertyIsLessThan>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>4.5</Literal>
              </PropertyIsLessThan>
            </And>
          </Filter>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">#000083</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">white</CssParameter>
                  <CssParameter name="stroke-width">0.3</CssParameter>
                  <CssParameter name="stroke-opacity">0.41</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">#000083</CssParameter>
            </Fill>
          </PolygonSymbolizer>
        </Rule>
        <Rule>
          <Name>4.50 - 9.00 Teltonika EYE sensor BTSMP1 1</Name>
          <Filter xmlns="http://www.opengis.net/ogc">
            <And>
              <PropertyIsGreaterThanOrEqualTo>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>4.5</Literal>
              </PropertyIsGreaterThanOrEqualTo>
              <PropertyIsLessThan>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>9</Literal>
              </PropertyIsLessThan>
            </And>
          </Filter>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">#003caa</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">white</CssParameter>
                  <CssParameter name="stroke-width">0.3</CssParameter>
                  <CssParameter name="stroke-opacity">0.41</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">#003caa</CssParameter>
            </Fill>
          </PolygonSymbolizer>
        </Rule>
        <Rule>
          <Name>9.00 - 13.50 Teltonika EYE sensor BTSMP1 1</Name>
          <Filter xmlns="http://www.opengis.net/ogc">
            <And>
              <PropertyIsGreaterThanOrEqualTo>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>9</Literal>
              </PropertyIsGreaterThanOrEqualTo>
              <PropertyIsLessThan>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>13.5</Literal>
              </PropertyIsLessThan>
            </And>
          </Filter>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">#027dc6</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">white</CssParameter>
                  <CssParameter name="stroke-width">0.3</CssParameter>
                  <CssParameter name="stroke-opacity">0.41</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">#027dc6</CssParameter>
            </Fill>
          </PolygonSymbolizer>
        </Rule>
        <Rule>
          <Name>13.50 - 18.00 Teltonika EYE sensor BTSMP1 1</Name>
          <Filter xmlns="http://www.opengis.net/ogc">
            <And>
              <PropertyIsGreaterThanOrEqualTo>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>13.5</Literal>
              </PropertyIsGreaterThanOrEqualTo>
              <PropertyIsLessThan>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>18</Literal>
              </PropertyIsLessThan>
            </And>
          </Filter>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">#03bee3</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">white</CssParameter>
                  <CssParameter name="stroke-width">0.3</CssParameter>
                  <CssParameter name="stroke-opacity">0.41</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">#03bee3</CssParameter>
            </Fill>
          </PolygonSymbolizer>
        </Rule>
        <Rule>
          <Name>18.00 - 22.50 Teltonika EYE sensor BTSMP1 1</Name>
          <Filter xmlns="http://www.opengis.net/ogc">
            <And>
              <PropertyIsGreaterThanOrEqualTo>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>18</Literal>
              </PropertyIsGreaterThanOrEqualTo>
              <PropertyIsLessThan>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>22.5</Literal>
              </PropertyIsLessThan>
            </And>
          </Filter>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">#05ffff</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">white</CssParameter>
                  <CssParameter name="stroke-width">0.3</CssParameter>
                  <CssParameter name="stroke-opacity">0.41</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">#05ffff</CssParameter>
            </Fill>
          </PolygonSymbolizer>
        </Rule>
        <Rule>
          <Name>22.50 - 27.00 Teltonika EYE sensor BTSMP1 1</Name>
          <Filter xmlns="http://www.opengis.net/ogc">
            <And>
              <PropertyIsGreaterThanOrEqualTo>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>22.5</Literal>
              </PropertyIsGreaterThanOrEqualTo>
              <PropertyIsLessThan>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>27</Literal>
              </PropertyIsLessThan>
            </And>
          </Filter>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">#82ff80</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">white</CssParameter>
                  <CssParameter name="stroke-width">0.3</CssParameter>
                  <CssParameter name="stroke-opacity">0.41</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">#82ff80</CssParameter>
            </Fill>
          </PolygonSymbolizer>
        </Rule>
        <Rule>
          <Name>27.00 - 31.50 Teltonika EYE sensor BTSMP1 1</Name>
          <Filter xmlns="http://www.opengis.net/ogc">
            <And>
              <PropertyIsGreaterThanOrEqualTo>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>27</Literal>
              </PropertyIsGreaterThanOrEqualTo>
              <PropertyIsLessThan>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>31.5</Literal>
              </PropertyIsLessThan>
            </And>
          </Filter>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">#ffff00</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">white</CssParameter>
                  <CssParameter name="stroke-width">0.3</CssParameter>
                  <CssParameter name="stroke-opacity">0.41</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">#ffff00</CssParameter>
            </Fill>
          </PolygonSymbolizer>
        </Rule>
        <Rule>
          <Name>31.50 - 36.00 Teltonika EYE sensor BTSMP1 1</Name>
          <Filter xmlns="http://www.opengis.net/ogc">
            <And>
              <PropertyIsGreaterThanOrEqualTo>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>31.5</Literal>
              </PropertyIsGreaterThanOrEqualTo>
              <PropertyIsLessThan>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>36</Literal>
              </PropertyIsLessThan>
            </And>
          </Filter>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">#fdaa00</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">white</CssParameter>
                  <CssParameter name="stroke-width">0.3</CssParameter>
                  <CssParameter name="stroke-opacity">0.41</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">#fdaa00</CssParameter>
            </Fill>
          </PolygonSymbolizer>
        </Rule>
        <Rule>
          <Name>36.00 - 40.50 Teltonika EYE sensor BTSMP1 1</Name>
          <Filter xmlns="http://www.opengis.net/ogc">
            <And>
              <PropertyIsGreaterThanOrEqualTo>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>36</Literal>
              </PropertyIsGreaterThanOrEqualTo>
              <PropertyIsLessThan>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>40.5</Literal>
              </PropertyIsLessThan>
            </And>
          </Filter>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">#fc5500</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">white</CssParameter>
                  <CssParameter name="stroke-width">0.3</CssParameter>
                  <CssParameter name="stroke-opacity">0.41</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">#fc5500</CssParameter>
            </Fill>
          </PolygonSymbolizer>
        </Rule>
        <Rule>
          <Name>40.50 - 45.00 Teltonika EYE sensor BTSMP1 1</Name>
          <Filter xmlns="http://www.opengis.net/ogc">
            <And>
              <PropertyIsGreaterThanOrEqualTo>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>40.5</Literal>
              </PropertyIsGreaterThanOrEqualTo>
              <PropertyIsLessThan>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>45</Literal>
              </PropertyIsLessThan>
            </And>
          </Filter>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">#fa0000</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">white</CssParameter>
                  <CssParameter name="stroke-width">0.3</CssParameter>
                  <CssParameter name="stroke-opacity">0.41</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">#fa0000</CssParameter>
            </Fill>
          </PolygonSymbolizer>
        </Rule>
        <Rule>
          <Name>45.00 - 49.50 Teltonika EYE sensor BTSMP1 1</Name>
          <Filter xmlns="http://www.opengis.net/ogc">
            <And>
              <PropertyIsGreaterThanOrEqualTo>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>45</Literal>
              </PropertyIsGreaterThanOrEqualTo>
              <PropertyIsLessThan>
                <PropertyName>Teltonika EYE sensor BTSMP1 1</PropertyName>
                <Literal>49.5</Literal>
              </PropertyIsLessThan>
            </And>
          </Filter>
          <PointSymbolizer>
            <Graphic>
              <Mark>
                <WellKnownName>circle</WellKnownName>
                <Fill>
                  <CssParameter name="fill">#800000</CssParameter>
                </Fill>
                <Stroke>
                  <CssParameter name="stroke">white</CssParameter>
                  <CssParameter name="stroke-width">0.3</CssParameter>
                  <CssParameter name="stroke-opacity">0.41</CssParameter>
                </Stroke>
              </Mark>
              <Size>10</Size>
            </Graphic>
          </PointSymbolizer>
          <PolygonSymbolizer>
            <Fill>
              <CssParameter name="fill">#800000</CssParameter>
            </Fill>
          </PolygonSymbolizer>
        </Rule>
      </FeatureTypeStyle>
    </UserStyle>
  </NamedLayer>
</StyledLayerDescriptor>`;