export const analysesEnTranslations = {
    'ANALYSIS_DESCRIPTIONS': {
        meteogram_agro_hd: `<p>meteoblue meteogram Agro shows the development of the weather with diagrams of air
        temperature, wind velocity and direction on the ground, as well as precipitation, clouds,
        spraying windows and evaporation. The data are valid for the forecast area of a model grid
        cell, without indicating special conditions of the selected place (e.g. differences in height).</p>
        <p>The display of all weather variables takes place in local time.</p>
        <p>The temperature is shown by a colour curve at hourly intervals. Weather symbols are on the
        same diagram to make a brief summary of the weather conditions.</p>
        <p>The precipitation is shown with dark blue bars for the total rain and light blue bars for rain
        showers. The precipitation probability (%) is displayed by a pink curve.</p>
        <p>The cloud cover is shown as function of the altitude (asl) and of the total cloud coverage. A
        brown bar shows the altitude above sea level.</p>
        <p>The Spray window helps identifying suitable periods for applying crop protection measures
        by showing suitable (green), less suitable (yellow) and unsuitable (red) periods for
        application. The conditions are calculated from wind, precipitation, temperature and
        humidity. The recommendation must be verified just before the application with the current
        actual weather conditions.</p>
        <p>The next diagram shows the balance of evaporation and transpiration (evapotranspiration)
        in mm and relative humidity (%) in the area.</p>
        <p>The wind diagram displays the wind direction with wind barbs, as well as the wind speed
        (light orange curve) and wind gusts (bold curve) in hourly intervals.</p>
        <p>For more information, see https://content.meteoblue.com/en/content/view/full/1625</p>
        `,
        meteogram_14day: `<p>The 14-day meteogram shows the weather forecast for the next fortnight with weather
        symbols, a range of maximum, average and minimum temperatures, precipitation amounts,
        as well as the precipitation probability for each day. The range between possible minimum
        and maximum values shows the possible weather developments.</p>
        <p>The weather symbols and the daily temperature maxima and minima appear below the
        dates (defined in current local time) to give an approximate overview of the expected
        weather conditions. They are chosen based on the most likely outcome for each day. If the
        spread of outcomes is large (low convergence, see below), the most likely outcome may
        differ from the average outcome, which in turn can lead to inconsistencies between
        weather symbols and precipitation amounts shown in the bottom panel.</p>
        <p>The temperature diagram shows the expected maximum and minimum temperatures
        together with their uncertainty ranges as established by comparing several weather models
        (ensemble). The thick line in the middle of each range indicates the most likely outcome.</p>
        <p>The precipitation diagram shows daily precipitation amounts with their ranges of minimum
        and maximum amounts possible indicated by black error bars. The probability of
        precipitation is indicated at the bottom of the diagram.</p>
        <p>The 14-day meteogram can be helpful to anticipate the risk of extreme weather. A useful
        approach is monitoring the forecast daily during critical periods. If an extreme event (frost,
        very high temperature, heavy precipitation) appears on day 14, watch for the following:</p>
        <ul>
        <li>The difference between maximums and minimums: If the difference is low, models
        agree, and the event is more likely to occur. If the difference is high, the event is less
        likely to occur.</li>
        <li>Consistency: If all weather variables indicate the same situation an event is more
        likely. For example, an extreme drop in temperature combined with increasing wind,
        clouds, precipitation could indicate a blizzard. If only some of the weather variables
        indicate the same situation, e.g. heavy rain is forecast but no drop in temperature
        and only few clouds, the event is less likely to occur or will not be very pronounced;
        continue watching.</li>
        </ul>
        <p>For more information, see https://content.meteoblue.com/ru/time-
        dimensions/forecast/meteograms/14-days.</p>`,
        
        pictoprintDayUvRain: `<p>The forecast pictograms (Pictoprint Day UV Rain) shows a daily forecast for 7 days with daily
        temperatures, wind speed, wind direction, UV index, precipitation amount and
        rainSPOT. Additionally it includes the times for sunrise and sunset.</p>
        <p>rainSPOT shows precipitation in a 15km radius around the forecast location. For more
        information on rainSPOT see https://content.meteoblue.com/en/time-
        dimensions/forecast/rainspot.</p>`,
        
        meteogram_soiltraffic: `<h2>Meteogram Agro Trafficability</h2>
        <p>The meteogram Agro Trafficability shows the capacity of the soil to support moving vehicles based on the development of water content in the topsoil (0-10cm). The development of Soil Trafficability is represented in line graph (black) that moves though 3 trafficability conditions, good trafficability (green), restricted trafficability (yellow) and no trafficability (red).</p>
        <p>The soil trafficability development is calculated for four types of soil. Sand, Silty Loam, Silt and Clay, each of which has a different water holding capacity and react differently to weather conditions.</p>
        <p>The calculation uses information of precipitation amount, sunshine hours, soil-specific water holding capacity and more.</p>
        `,
        
        meteogram_agroSowing: `<p>The meteogram Agro Sowing shows the development of soil temperature and precipitation
        for 7 days. Additionally it includes sowing windows for maize, wheat, barley, rapeseed,
        potato, and sugar beets. The sowing window is separated into three categories: periods of
        hourly intervals that are suitable (green), less suitable (yellow), and unsuitable (red) for
        sowing.</p>
        <p>For more information, see https://content.meteoblue.com/hu/access-options/meteoblue-
        weather-api/images#Meteogram_AgroSowing.</p>`,
        
        meteogram_agroSpraying: `<p>The meteogram Agro Spraying shows the forecasts of the temperature and precipitation for
        7 days. Additionally, it includes the spraying window. The spraying window helps identifying
        suitable periods for crop protection measures by showing suitable (green), less suitable
        (yellow), and unsuitable (red) periods for application. Spraying conditions are calculated
        from wind, precipitation, temperature, and humidity.</p>
        <p>For more information, see https://content.meteoblue.com/hu/access-options/meteoblue-
        weather-api/images#Meteogram_AgroSpraying.</p>`,
        
        history_frostrisk: `<p>Frost risk is the probability of cold events, defined as temperatures below a certain
        temperature threshold lasting for a certain time. The top diagram shows the likelihood of a
        cold events per day and per week. The second diagram shows periods of tolerable risk. If,
        for example, your tolerable risk for a frost event was 20%, you would schedule sowing the
        period indicated by the extent of the fifth grey bar counting from the bottom. The third
        diagram shows the occurrences of cold events for each year in the last 30 years.</p>
        <p>For more information, see https://content.meteoblue.com/hu/access-options/history/risk-
        assessment.</p>`,
        
        protected_areas_hint: `<p><a href="#" (click)="activateAoiDraw()">Draw</a> or select objects in "Areas of Interest" layer and click Run</p><p>Analysis “Protected areas” provides a layer where interventions with chemicals are not permitted/recommended due to distance from water resources.
        Algorithm takes field geometry, water resources layer and defined distance from specifications/regulations and calculates bounding box where interventions are not allowed.</p>`,
        
        fertilization_hint: `<p><a href="#" (click)="activateAoiDraw()">Draw</a> or select objects in "Areas of Interest" layer and click Run <a href="#" onclick="var el = document.querySelector('.more-fertilization'); el.style.display=el.style.display=='block' ? 'none' : 'block'">...</a></p>
        <p style="display:none" class="more-fertilization">Based on the heterogeneity of the field, variable rate application map can be calculated to distribute fertilizer on the field effectively. It calculates areas where to apply more or less amount of inputs. When the application map is calculated based on the yield management zones layer, it is time stable during the season based on the regulations of applications theirself. If weather conditions are appropriate for the intervention, other analyses could be taken into account - Soil trafficability, Spray window. It can be used for both types of fertilizers - pre-planting fertilization, nitrogen fertilization. Output is a application map providing recommended amount of fertilizer per square meter.</p>`,
        
        "ndvi-average-daily-change-hint": `<p><a href="#" (click)="activateAoiDraw()">Draw</a> or select objects in "Areas of Interest" layer and click Run<br/>
        <a href="#" onclick="var el = document.querySelector('.more-ndvi-average-change'); el.style.display=el.style.display=='block' ? 'none' : 'block'">...</a></p>
        <p style="display:none" class="more-ndvi-average-change">Layer provides information about the average daily change of the NDVI index during selected period. It can provide farmer information about result of some interventions or events happened in last days, e.g. when there were very hot and dry days, or opposite very heavy rains or some hailstorm. Or it can identify spots where a intervention is necessary to support growing or flowering of crop. With the granularity of the layer, it can identify spots with some unregularities in the field. The layer shows average change of index between spots captured on subsequent images without clouds - it is the current one and nearest previous one without clouds, but the maximum time distance between 2 non-cloudy image is 2 weeks.</p>`,
        
        click_to_run: 'Click a point or feature on the map to run analysis',

        'crop-weather-monitoring': `<p>Analysis allows you to monitor the drought and frost risks for a particular crop at a particular location during its growth period. Data include the current season’s weather to date, a 7-day forecast, and a seasonal forecast.</p>
        <p>In addition, single or multiple reference years can be included for comparison. This service supports operational decisions such as when to plant, fertilize, irrigate, etc</p><p>Crop growing phases are based on cumulated growing degree days (GDD).</p>
        <p>A webinar explaining the diagrams is at <a href="https://www.euxdat.eu/2020/10/24/euxdat-video-pilots-session-1/">https://www.euxdat.eu/2020/10/24/euxdat-video-pilots-session-1/</a>.</p>`
    },
    ANALYSIS_TITLES: {
        'meteogram_agro_hd': '7 day agro meteogram',
        'meteogram_14day': '14 day meteogram',
        'pictoprintDayUvRain': 'Forecast pictograms',
        'meteogram_soiltraffic': 'Soil trafficability',
        'meteogram_agroSowing': 'Sowing window',
        'meteogram_agroSpraying': 'Spray window',
        'history_frostrisk': 'Frost risk',
        'protected-areas': 'Protected areas',
        'fertilization-map': "Fertilization map",
        'ndvi-average-daily-change': 'NDVI Average Daily Change',
        'crop-weather-monitoring': 'Crop weather risk monitoring and prediction'
    },
    ANALYSIS: {
        RunAnalysisOnAreas: 'Run analysis on features',
        All: 'All',
        Selected: 'Selected',
        Back: 'Back to analysis list'
    },
    ANALYSIS_PARAMS: {
        "fertilizationMethod": "Fertilization method",
        "fertilizerVolume": "Fertilizer volume",
        "add": "Additive",
        "comp": "Compensating",
        "offset": "Offset (m)",
        "years_actual_end": "Last year of recent comparison period",
        "years_actual_start": "First year of recent comparison period, from 1985 onwards",
        "years_clima_end": "Last year of past comparison period",
        "years_clima_start": "First year of past comparison period, from 1985 onwards",
        "month_start": "Initial month of crop typical growing season",
        "month_end": "Final month of crop typical growing season"
    },
    PANEL_HEADER: {
        ANALYSIS: 'Analysis'
    },
    FieldHistoryDataDidntExist: "Field history data didn't exist",
    'Analysis failed': 'Analysis failed'
}