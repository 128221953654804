<div class="modal in" id="info-dialog" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4>
                   {{HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS_TITLES', data.analysis.name)}}
                </h4>
                <button type="button" class="btn-close float-end" (click)="close()" data-dismiss="modal" ></button>
            </div>
            <div class="modal-body">
               <img [src]="data.url" *ngIf="data.analysis.provider == 'meteoblue-image'" [ngStyle]="imageStyle()"/>
               <iframe [src]="data.url" *ngIf="data.analysis.provider == 'meteoblue-iframe-pyapi'" [ngStyle]="iframeStyle()"></iframe>
              <p>
                <button class="btn btn-" (click)="descExpanded = !descExpanded">
                    <i class="icon-info-sign" data-toggle="tooltip" data-container="body" data-placement="auto"></i>
                </button>
              </p> 
               <div style="max-height: 16em; overflow-y: auto" [hidden]="!descExpanded" [innerHtml]="HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS_DESCRIPTIONS', data.analysis.name)"></div>
            </div>
        </div>
    </div>
</div>