export default function (layerArray) {

  const temCategs = [
    { value: '>46', color: '#950221' },
    { value: 45, color: '#B40957' },
    { value: 43, color: '#D40F8C' },
    { value: 41, color: '#F316C2' },
    { value: 39, color: '#F31861' },
    { value: 37, color: '#F31A00' },
    { value: 35, color: '#FF0100' },
    { value: 33, color: '#FC4F00' },
    { value: 31, color: '#FF6600' },
    { value: 29, color: '#F88D00' },
    { value: 27, color: '#FCAC05' },
    { value: 25, color: '#FDCA0C' },
    { value: 23, color: '#F8DF0B' },
    { value: 21, color: '#FFF600' },
    { value: 19, color: '#D8F7A1' },
    { value: 17, color: '#B5FF33' },
    { value: 15, color: '#79D030' },
    { value: 13, color: '#007229' },
    { value: 11, color: '#007229' },
    { value: 9, color: '#297B5D' },
    { value: 7, color: '#10B87A' },
    { value: 5, color: '#00C848' },
    { value: 3, color: '#00E452' },
    { value: 1, color: '#00EF7C' },
    { value: -1, color: '#A0EAF7' },
    { value: -3, color: '#80E0F7' },
    { value: -5, color: '#51CBFA' },
    { value: -7, color: '#44B1F6' },
    { value: -9, color: '#348CED' },
    { value: -11, color: '#2460E2' },
    { value: -13, color: '#1B34D7' },
    { value: -15, color: '#1C0DCF' },
    { value: -17, color: '#5D04D8' },
    { value: -19, color: '#8904E1' },
    { value: -21, color: '#AF05E3' },
    { value: -23, color: '#CE0BDC' },
    { value: -25, color: '#A30CA8' },
    { value: -27, color: '#7B0C88' },
    { value: -29, color: '#560B69' },
    { value: -31, color: '#360A4E' },
    { value: '<32', color: '#0E0E0E' }
  ];

  layerArray.filter(l => l.get('title') == 'Temperature')[0]
    .set('legendImage', `<svg  xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            width="150"
            height="370"
            viewBox="0 -3 33.229166 99.375002"
            version="1.1"
            inkscape:version="0.92.4 5da689c313, 2019-01-14"
            sodipodi:docname="legend.svg"> <g
            inkscape:label="Layer 1"
            inkscape:groupmode="layer"
            transform="translate(0,0)">
            ${
      temCategs.map(i => ` <rect
                style="fill:${i.color};fill-opacity:1;stroke-width:0.26458332"
                width="3.96875"
                height="3.4017856"
                x="${0.75 + (i.value < 0 ? 20 : 0)}"
                y="${(i.value < 0 ? -80 : 0) + temCategs.indexOf(i) * 3.5127856}" />
             <text
                xml:space="preserve"
                style="font-style:normal;font-weight:normal;font-size:3.52777767px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                x="5.1026788"
                y="221.78268"
                ><tspan
                  sodipodi:role="line"
                  x="${5.1026788 + (i.value < 0 ? 20 : 0)}"
                  y="${(i.value < 0 ? -80 : 0) + 2.2 + temCategs.indexOf(i) * 3.5127856}"
                  style="stroke-width:0.26458332"> <tspan
            style="font-size:2.82222223px"
            >${i.value}</tspan></tspan></text>`).join('\n')

      }
            <text
            xml:space="preserve"
            style="font-style:normal;font-weight:normal;font-size:3.12777767px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
            x="1.1339285"
            y="87.97617"
            id="text4566"><tspan
              sodipodi:role="line"
              id="tspan4564"
              x="1.1339285"
              y="87.97617"
              style="stroke-width:0.26458332">(°C)</tspan></text>
         </g>
       </svg>`);


  const windCategs = [
    { value: (120 * 1000 / 3600).toFixed(1), color: '#A0167C' },
    { value: (90 * 1000 / 3600).toFixed(1), color: '#CB00D1' },
    { value: (70 * 1000 / 3600).toFixed(1), color: '#E80046' },
    { value: (50 * 1000 / 3600).toFixed(1), color: '#F71C00' },
    { value: (40 * 1000 / 3600).toFixed(1), color: '#F85500' },
    { value: (35 * 1000 / 3600).toFixed(1), color: '#F98E00' },
    { value: (30 * 1000 / 3600).toFixed(1), color: '#FAC700' },
    { value: (25 * 1000 / 3600).toFixed(1), color: '#F2E80C' },
    { value: (20 * 1000 / 3600).toFixed(1), color: '#DAE12C' },
    { value: (15 * 1000 / 3600).toFixed(1), color: '#C3DA4B' },
    { value: (10 * 1000 / 3600).toFixed(1), color: '#ABD26B' },
    { value: (8 * 1000 / 3600).toFixed(1), color: '#92CA8A' },
    { value: (5 * 1000 / 3600).toFixed(1), color: '#74BFA5' },
    { value: (2 * 1000 / 3600).toFixed(1), color: '#56B4BF' },
    { value: (1 * 1000 / 3600).toFixed(1), color: '#32A9D8' },
    { value: (0 * 1000 / 3600).toFixed(1), color: '#0E9FF1' }
  ];

  layerArray.filter(l => l.get('title') == 'Wind')[0]
    .set('legendImage', `<svg  xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            width="150"
            height="270"
            viewBox="0 -3 33.229166 63.375002"
            version="1.1"
            inkscape:version="0.92.4 5da689c313, 2019-01-14"
            sodipodi:docname="legend.svg"> <g
            inkscape:label="Layer 1"
            inkscape:groupmode="layer"
            transform="translate(0,0)">
            ${
      windCategs.map(i => ` <rect
                style="fill:${i.color};fill-opacity:1;stroke-width:0.26458332"
                width="3.96875"
                height="3.4017856"
                x="${0.75}"
                y="${ windCategs.indexOf(i) * 3.5127856}" />
             <text
                xml:space="preserve"
                style="font-style:normal;font-weight:normal;font-size:3.52777767px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                x="5.1026788"
                y="221.78268"
                ><tspan
                  sodipodi:role="line"
                  x="${5.1026788}"
                  y="${ 4 + windCategs.indexOf(i) * 3.5127856}"
                  style="stroke-width:0.26458332"> <tspan
            style="font-size:2.82222223px"
            >${i.value}</tspan></tspan></text>`).join('\n')

      }
            <text
            xml:space="preserve"
            style="font-style:normal;font-weight:normal;font-size:3.12777767px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
            x="1.1339285"
            y="58.97617"
            id="text4566"><tspan
              sodipodi:role="line"
              id="tspan4564"
              x="1.1339285"
              y="59.97617"
              style="stroke-width:0.26458332">(m/s)</tspan></text>
         </g>
       </svg>`)

  const rainCategs = [
    { value: 60, color: '#FB1403' },
    { value: 45, color: '#FC1303' },
    { value: 30, color: '#FF6101' },
    { value: 25, color: '#FFAD02' },
    { value: 20, color: '#DAFF35' },
    { value: 15, color: '#AEFF03' },
    { value: 10, color: '#02C621' },
    { value: 8, color: '#039787' },
    { value: 6, color: '#0268D5' },
    { value: 4, color: '#017CFE' },
    { value: 2, color: '#5A7BF8' },
    { value: 1, color: '#6994FC' },
    { value: '<1', color: '#85F7F4' }
  ];

  layerArray.filter(l => l.get('title') == 'Cloud cover / precipitation')[0]
    .set('legendImage', `<svg  xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            width="150"
            height="230"
            viewBox="0 -3 33.229166 57.375002"
            version="1.1"
            inkscape:version="0.92.4 5da689c313, 2019-01-14"
            sodipodi:docname="legend.svg"> <g
            inkscape:label="Layer 1"
            inkscape:groupmode="layer"
            transform="translate(0,0)">
            ${
      rainCategs.map(i => ` <rect
                style="fill:${i.color};fill-opacity:1;stroke-width:0.26458332"
                width="3.96875"
                height="3.4017856"
                x="${0.75}"
                y="${ rainCategs.indexOf(i) * 3.5127856}" />
             <text
                xml:space="preserve"
                style="font-style:normal;font-weight:normal;font-size:3.52777767px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
                x="5.1026788"
                y="221.78268"
                ><tspan
                  sodipodi:role="line"
                  x="${5.1026788}"
                  y="${ 4 + rainCategs.indexOf(i) * 3.5127856}"
                  style="stroke-width:0.26458332"> <tspan
            style="font-size:2.82222223px"
            >${i.value}</tspan></tspan></text>`).join('\n')

      }
            <text
            xml:space="preserve"
            style="font-style:normal;font-weight:normal;font-size:3.12777767px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
            x="1.1339285"
            y="58.97617"
            id="text4566"><tspan
              sodipodi:role="line"
              id="tspan4564"
              x="1.1339285"
              y="50.97617"
              style="stroke-width:0.26458332">(mm)</tspan></text>
         </g>
       </svg>`)
}