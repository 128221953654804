<div class="card card- mainpanel" [hidden]="!isVisible()">
    <hs-panel-header name="analysis" [title]="HsLanguageService.getTranslation('PANEL_HEADER.ANALYSIS')"></hs-panel-header>
    <div class="card-body">
        <div class="card-body">
            <ul class="list-group" *ngIf = "analysisMenuExpanded">
                <a href="#" class="list-group-item" (click)="chooseAnalysis(item)" *ngFor="let item of analysisList">{{HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS_TITLES', item.name)}}</a>
            </ul>
            <p>
                <a href="#" (click)="analysisMenuExpanded = true; currentAnalysis = null" *ngIf = "!analysisMenuExpanded"><img [src]="MainService.assetsPath + 'images/keyboard_backspace-24px.svg'"/> {{HsLanguageService.getTranslation('ANALYSIS.Back')}}</a>
            </p>
            
            <strong *ngIf="currentAnalysis"><p>{{HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS_TITLES', currentAnalysis.name)}}</p></strong>
<!--            <div class="dropdown">
                <button class="btn btn-light  btn-outline-primary btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" ng-aria-expanded="analysisMenuExpanded" (click)="analysisMenuExpanded = !analysisMenuExpanded">
                    <img [hidden]="!loading" [src]="MainService.assetsPath + 'images/ajax-loader.gif'" translate="">
                    <span *ngIf="currentAnalysis">{{HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS_TITLES', currentAnalysis.name)}}</span>
                    <span *ngIf="currentAnalysis==null">{{HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS_TITLES', 'Choose analysis')}}</span>
                </button>
                <div class="dropdown-menu" [ngClass]="{'show': analysisMenuExpanded}" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#"  (click)="chooseAnalysis(item)"></a>
                </div>
            </div>
            -->
            <div class="alert alert-primary" [hidden]="!currentAnalysis || hintClosed">
                <button type="button" class="btn-close float-end" (click)="closeHint()"></button>
                <span [innerHtml]="analysisHint"></span>
            </div>
            <div class="form-group mt-1" *ngIf="currentAnalysis!=null" [hidden]="!(currentAnalysis.provider.indexOf('lesprojekt') > -1)">
                <label class="control-label">{{HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS', 'RunAnalysisOnAreas') }}:</label>
                <select class="form-control" [(ngModel)]="featureGroup">
                    <!--<option value="all" [disabled]="FieAnalysisService.getAllFeatures(getAoiLayer()).length>1" selected="">
                        {{HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS', 'All') }}</option>-->
                    <option  value="selected">
                        {{HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS', 'Selected') }}</option>
                </select>
            </div>
            <div *ngIf="currentAnalysis && currentAnalysis.parameters">
                <div class="form-group" *ngFor="let item of currentAnalysis.parameters | keyvalue">
                    <label class="control-label">{{HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS_PARAMS', item.key) }}:</label>
            
                    <select class="form-control" [(ngModel)]="item.value.value" *ngIf="item.value.enum">
                        <option [value]="enum" *ngFor="let enum of item.value.enum">
                            {{HsLanguageService.getTranslationIgnoreNonExisting('ANALYSIS_PARAMS', enum)}}</option>
                    </select>
                    <div class="input-group" *ngIf="!item.value.enum && item.value.type == 'date'">
                        <input type="text" class="form-control" ngbDatepicker #d="ngbDatepicker" [(ngModel)]="item.value.value">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><i class="icon-calendarthree"></i></button>
                        </div>
                    </div>
                    <div class="input-group" *ngIf="!item.value.enum && !item.value.type">
                        <input type="text" class="form-control" [(ngModel)]="item.value.value">
                    </div>
                </div>
            </div>
            <div [hidden]="!(currentAnalysis && currentAnalysis.legend)">
                <p><strong>{{HsLanguageService.getTranslation('COMMON.legend')}}</strong></p>
                <span *ngIf="currentAnalysis && currentAnalysis.legend!=undefined" [innerHtml]="currentAnalysis.legend"></span>
            </div>
            <button class="btn btn-primary btn-block" [hidden]="!(currentAnalysis && currentAnalysis.provider == 'lesprojekt')" type="button" (click)="runAnalysis()">
                <img [hidden]="!loading" [src]="MainService.assetsPath + 'images/ajax-loader.gif'" translate="">
                {{HsLanguageService.getTranslation('Run analysis')}}
            </button>
        </div>
    </div>
</div>