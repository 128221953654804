export const translationOverrides = {
    "en": {
      "PANEL_HEADER": {"FEATURE_TABLE": "Field list"},
      LAYERS: {
        'soilLayerAbstract': 'Please view 5-6th page in <a href="https://tinyurl.com/y2wnb2ke" target="_blank">https://tinyurl.com/y2wnb2ke</a> and <a href="https://tinyurl.com/y3m8jqk6" target="_blank">https://tinyurl.com/y3m8jqk6</a>',
        'yieldLayerAbstract': 'This layer provides information about segmentation of fields with similar characteristics and similar production level - a variety of management zones approach. The yield productivity zones calculation uses multispectral images for last 8 years time serie (Landsat or Sentinel2), atmosphere corrections, EVI index, borders of fields (LPIS), optionaly crops in the period. It describes the heterogenity of fields based on status of vegetation in the selected time period. Number of zones corresponds with the heterogenity of field. But it is not possible to compare two fields with each other, values are calculated only for each field separately during the time period.'
      },
      "FEATURE_TABLE": {
        "Feature": "Field",
        "PIETEIKTAI": "Block number",
        "PIETEIKUMA": "Year",
        "PIETEIKT_1": "Culture code",
        "PIETEIKTA_": "Field area applied",
        "PIETEIKTIE": "Base support type",
        "link": "Link",
        "actions": "Actions",
        "area": "Calculated area",
        "Describe ndvi": "Describe NDVI",
        "open": "Open"
      }
    },
    "cs": {
      
    },
    "lv": {
      "open": "atvērt",
      "SENSORS": {
        "SENSORNAMES": {
          "EAG soil salin 120 cm": "Augsnes sāļums 120 cm dziļumā",
          "EAG soil salin 110 cm": "Augsnes sāļums 110 cm dziļumā",
          "EAG soil salin 100 cm": "Augsnes sāļums 100 cm dziļumā",
          "EAG soil salin 90 cm": "Augsnes sāļums 90 cm dziļumā",
          "EAG soil salin 80 cm": "Augsnes sāļums 80 cm dziļumā",
          "EAG soil salin 70 cm": "Augsnes sāļums 70 cm dziļumā",
          "EAG soil salin 60 cm": "Augsnes sāļums 60 cm dziļumā",
          "EAG soil salin 50 cm": "Augsnes sāļums 50 cm dziļumā",
          "EAG soil salin 40 cm": "Augsnes sāļums 40 cm dziļumā",
          "EAG soil salin 30 cm": "Augsnes sāļums 30 cm dziļumā",
          "EAG soil salin 20 cm": "Augsnes sāļums 20 cm dziļumā",
          "EAG soil salin 10 cm": "Augsnes sāļums 10 cm dziļumā",
          "Water level below surface": "Gruntsūdens līmenis",
          "EAG Soil moist 120 cm": "Augsnes mitrums 120 cm dziļumā",
          "EAG Soil moist 110 cm": "Augsnes mitrums 110 cm dziļumā",
          "EAG Soil moist 100 cm": "Augsnes mitrums 100 cm dziļumā",
          "EAG Soil moist 90 cm": "Augsnes mitrums 90 cm dziļumā",
          "EAG Soil moist 80 cm": "Augsnes mitrums 80 cm dziļumā",
          "EAG Soil moist 70 cm": "Augsnes mitrums 70 cm dziļumā",
          "EAG Soil moist 60 cm": "Augsnes mitrums 60 cm dziļumā",
          "EAG Soil moist 50 cm": "Augsnes mitrums 50 cm dziļumā",
          "EAG Soil moist 40 cm": "Augsnes mitrums 40 cm dziļumā",
          "EAG Soil moist 30 cm": "Augsnes mitrums 30 cm dziļumā",
          "EAG Soil moist 20 cm": "Augsnes mitrums 20 cm dziļumā",
          "EAG Soil moist 10 cm": "Augsnes mitrums 10 cm dziļumā",
          "Soil temp 120 cm": "Augsnes temperatūra 120 cm dziļumā",
          "Soil temp 110 cm": "Augsnes temperatūra 110 cm dziļumā",
          "Soil temp 100 cm": "Augsnes temperatūra 100 cm dziļumā",
          "Soil temp 90 cm": "Augsnes temperatūra 90 cm dziļumā",
          "Soil temp 80 cm": "Augsnes temperatūra 80 cm dziļumā",
          "Soil temp 70 cm": "Augsnes temperatūra 70 cm dziļumā",
          "Soil temp 60 cm": "Augsnes temperatūra 60 cm dziļumā",
          "Soil temp 50 cm": "Augsnes temperatūra 50 cm dziļumā",
          "Soil temp 40 cm": "Augsnes temperatūra 40 cm dziļumā",
          "Soil temp 30 cm": "Augsnes temperatūra 30 cm dziļumā",
          "Soil temp 20 cm": "Augsnes temperatūra 20 cm dziļumā",
          "Soil temp 10 cm": "Augsnes temperatūra 10 cm dziļumā",
          "Groundwater temperature": "Gruntsūdens temperatūra",
          "iMetos solar radiati": "Saules starojums",
          "iMetos HC Air temp": "Gaisa temperatūra",
          "iMetos HC Relative Humidity": "Relatīvais gaisa mitrums",
          "IMetos leaf wetness": "Ūdens nožūšanas laiks",
          "iMetos wind direction": "Vēja virziens",
          "iMetos wind spd max": "Vēja ātrums maks.",
          "iMetos wind speed": "Vēja ātrums",
          "iMetos precipitation": "Nokrišņu daudzums",
          "NDVI index max": "NDVI indeksa maks. vērtība",
          "NDVI index min": "NDVI indeksa min. vērtība",
          "NDVI index avg": "NDVI indeksa vidējā vērtība"
        },
        "SENSORTYPE": {
          "Salinity": "Augsnes sāļuma mērītājs",
          "Water Level": "Ūdens līmeņa mērītājs",
          "Humidity": "Augsnes mitruma mērītājs",
          "Water Thermometer": "Ūdens termometrs",
          "Thermometer": "Termometrs",
          "Leaf wetness": "Lapu mitruma mērītājs",
          "Rain gauge": "Nokrišņu mērītājs",
          "Radiation": "Saules starojuma mērītājs",
          "Anemometer": "Anemometrs",
          "Index value": "Indeksa vērtība",
        },
        "PHENOMENON": {
          "Temperature": "Temperatūra",
          "Air humidity": "Gaisa mitrums",
          "Water temperature": "Ūdens temperatūrs",
          "Water level": "Ūdens līmenis",
          "Soil salinity": "Augsnes sāļums",
          "Depth below surface": "Dziļums zem virsmas",
          "Water level below surface": "Ūdens līmenis zem virsmas",
          "Soil moisture": "Augsnes mitrums",
          "Soil temperature": "Augsnes temperatūra",
          "Soil Salinity": "Augsnes sāļums",
          "Precipitation": "Nokrišņu daudzums",
          "Relative Humidity": "Relatīvais mitrums",
          "Relative value": "Relatīvā vērtība",
          "Groundwater temperature": "Gruntsūdeņu temperatūra",
          "Solar radiation": "Saules radiācija",
          "Air temperature": "Gaisa temperatūra",
          "Azimuth": "Vēja virziens",
          "Speed": "Vēja ātrums",
        },
        "at": "",
        "wind direction": "Vēja virziens",
        "wind speed": "vēja ātrums",
        "wind speed max": "maks. vēja ātrums",
        "Time": "Laiku",
        "Pressure": "Spiediens",
        "Vapour pressure deficit": "Tvaika spiediena deficītu",
        "Relative value": "Relatīvo vērtību"
      },
      LAYERMANAGER: {
        'Other': 'Citi',
        'Vegetation indexes and satellite imagery': 'Veģetācijas indeksi un satelītattēli',
      },
      LAYERS: {
        'Water bodies and rivers': 'Ūdens tilpnes un upes',
        'Natura-2000 protected areas': 'Natura-2000 aizsargātie apvidi',
        'Land value:': 'Zemes vērtība',
        'Temperature observations': 'Temperatūras novērojumi',
        'Satellite and pressure': 'Satelītkarte un spiediens',
        Wind: 'Vējš',
        Temperature: 'Temperatūra',
        'Cloud cover / precipitation': 'Mākoņainums un nokrišņi',
        'Areas of interest': 'Interešu apgabali (lauki)',
        Bookmarks: 'Grāmatzīmes',
        'Sensor units': 'Sensoru vienības',
        'Infrared satellite map (Vilcini)': 'Infrasarkanā karte (Vilcini)',
        'MSAVI2 index (Vilcini)': 'MSAVI2 indekss (Vilcini)',
        'NDVI index (Vilcini)': 'NDVI indekss (Vilcini)',
        'Yield productivity zones': 'Ražas produktivitātes zonas',
        'Street map': 'Ceļu karte',
        'soilLayerAbstract': 'Lūdzu, skatīt 5-6 lpp: <a href="https://tinyurl.com/y2wnb2ke" target="_blank">https://tinyurl.com/y2wnb2ke</a> un <a href="https://tinyurl.com/y3m8jqk6" target="_blank">https://tinyurl.com/y3m8jqk6</a>',
        'yieldLayerAbstract': 'Šis slānis sniedz informāciju par lauku segmentēšanu ar līdzīgām īpašībām un līdzīgu ražošanas līmeni - pieeja dažādām pārvaldības zonām. Ražas produktivitātes zonu aprēķināšanā tiek izmantoti pēdējo 8 gadu laika sēriju (Landsat vai Sentinel2) multispektrālie attēli, atmosfēras korekcijas, EVI indekss, lauku robežas (LPIS), izvēles kultūraugi attiecīgajā periodā. Tas apraksta lauku heterogenitāti, pamatojoties uz veģetācijas stāvokli izvēlētajā laika periodā. Zonu skaits atbilst lauka neviendabīgumam. Bet divus laukus nav iespējams salīdzināt savā starpā, vērtības tiek aprēķinātas tikai katram laukam atsevišķi laika periodā.'
      },
      "PANEL_HEADER": {"FEATURE_TABLE": "Lauku saraksts"},
      "FEATURE_TABLE": {
        "Feature": "Lauks",
        "PIETEIKTAI": "Bloka numurs",
        "PIETEIKUMA": "Gads",
        "PIETEIKT_1": "Kultūras kods",
        "PIETEIKTA_": "Pieteiktā lauka platiba",
        "PIETEIKTIE": "Pamata atbalsta veids",
        "link": "Saite",
        "actions": "Darbības",
        "area": "Apr. Platība",
        "Describe ndvi": "Aprakstīt NDVI",
        "open": "Atvērt"
      }
    }
}