import { Component, ViewRef } from '@angular/core';
import { HsDialogComponent, HsDialogContainerService, HsLanguageService, HsDialogItem } from 'hslayers-ng';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'hs-analysis-modal',
    templateUrl: './info.html'
})
export class InfoComponent implements HsDialogComponent {
    infoModalVisible = true;
    descExpanded = false;
    constructor(
        public HsLanguageService: HsLanguageService, 
        private HsDialogContainerService: HsDialogContainerService,
        private sanitizer: DomSanitizer) {
        
    }
    dialogItem?: HsDialogItem;
    viewRef: ViewRef;
    data: { analysis; url: any };

    imageStyle() {
        return { "max-width": "calc(100% - 5em)", "max-height": this.descExpanded ? "calc(100vh - 28rem)" : "calc(100vh - 11rem)" };
    }

    iframeStyle() {
        return { 
            "max-width": "calc(100% - 5em)", 
            "max-height": this.descExpanded ? "calc(100vh - 28rem)" : "calc(100vh - 11rem)",
            "height": this.descExpanded ? "calc(100vh - 28rem)" : "calc(100vh - 11rem)",
            "width": "100%",
            "border": "0" 
        };
    }

    ngOnInit(): void {
        if (this.data.analysis.descInDialog) {
            this.data.analysis.descInDialog = this.sanitizer.bypassSecurityTrustHtml(this.data.analysis.descInDialog);
        }
    }

    close(): void {
        this.HsDialogContainerService.destroy(this);
    }
}