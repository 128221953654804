import { ImageWMS, TileWMS } from "ol/source";
import Tile from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { GeoJSON } from "ol/format";

/*
export default {
  user_id: 22, //Needed for senslogOT
  group: "Mandolin", //Needed for MapLogOT
  user: "mandolin", //Needed for MapLogOT,
  viewCenter: [1502473.0572929683, 6323033.494183323],
  viewZoom: 11,
  title: "FIE20 - Mandolin",
  layers: [
    new Tile({
      properties: {
        title: "Pole",
        path: "Dolní Újezd",
      },
      source: new TileWMS({
        url: "https://gis.lesprojekt.cz/qgisserver/cgi-bin/qgis_mapserv.fcgi?map=/opt/RVI4S1/dolni-ujezd/asahi-breweries/asahi-zddu.qgs",
        params: {
          LAYERS: "pole",
          INFO_FORMAT: undefined,
          FORMAT: "image/png; mode=8bit",
        },
        crossOrigin: "anonymous",
      }),
      visible: true,
    }),

    new ImageLayer({
      properties: {
        title: "Ječmen jarní 2022 avg NDVI output",
        path: "Dolní Újezd",
      },
      source: new ImageWMS({
        url: "https://gis.lesprojekt.cz/qgisserver/cgi-bin/qgis_mapserv.fcgi?map=/opt/RVI4S1/dolni-ujezd/asahi-breweries/asahi-zddu.qgs",
        params: {
          LAYERS: "jecmen-jarni-zddu-2022-avg_ndvi",
          INFO_FORMAT: undefined,
          FORMAT: "image/png; mode=8bit",
        },
        crossOrigin: "anonymous",
      }),
      visible: false,
    }),

    new ImageLayer({
      properties: {
        title: "Ječmen jarní 2022 avg NDVI 2",
        path: "Dolní Újezd",
      },
      source: new ImageWMS({
        url: "https://gis.lesprojekt.cz/qgisserver/cgi-bin/qgis_mapserv.fcgi?map=/opt/RVI4S1/dolni-ujezd/asahi-breweries/asahi-zddu.qgs",
        params: {
          LAYERS: "jecmen-jarni-zddu-2022-avg_ndvi2",
          INFO_FORMAT: undefined,
          FORMAT: "image/png; mode=8bit",
        },
        crossOrigin: "anonymous",
      }),
      visible: false,
    }),

    new Tile({
      properties: {
        title: "Ječmen NDVI 26-03-2022",
        path: "Dolní Újezd",
      },
      source: new TileWMS({
        url: "https://gis.lesprojekt.cz/qgisserver/cgi-bin/qgis_mapserv.fcgi?map=/opt/RVI4S1/dolni-ujezd/asahi-breweries/asahi-zddu.qgs",
        params: {
          LAYERS: "ndvi_26-3-2022_jecmen",
          INFO_FORMAT: undefined,
          FORMAT: "image/png; mode=8bit",
        },
        crossOrigin: "anonymous",
      }),
      visible: false,
    }),

    new Tile({
      properties: {
        title: "Ječmen NDVI 10-05-2022",
        path: "Dolní Újezd",
      },
      source: new TileWMS({
        url: "https://gis.lesprojekt.cz/qgisserver/cgi-bin/qgis_mapserv.fcgi?map=/opt/RVI4S1/dolni-ujezd/asahi-breweries/asahi-zddu.qgs",
        params: {
          LAYERS: "ndvi_10-5-2022_jecmen",
          INFO_FORMAT: undefined,
          FORMAT: "image/png; mode=8bit",
        },
        crossOrigin: "anonymous",
      }),
      visible: false,
    }),

    new Tile({
      properties: {
        title: "Ječmen d NDVI 10-05-2022_26-03-2022",
        path: "Dolní Újezd",
      },
      source: new TileWMS({
        url: "https://gis.lesprojekt.cz/qgisserver/cgi-bin/qgis_mapserv.fcgi?map=/opt/RVI4S1/dolni-ujezd/asahi-breweries/asahi-zddu.qgs",
        params: {
          LAYERS: "rozdil_ndvi_10-5-2022_26-3-2022_jecmen",
          INFO_FORMAT: undefined,
          FORMAT: "image/png; mode=8bit",
        },
        crossOrigin: "anonymous",
      }),
      visible: false,
    }),

    new Tile({
      properties: {
        title: "DÚ NDVI 26-03-2022",
        path: "Dolní Újezd",
      },
      source: new TileWMS({
        url: "https://gis.lesprojekt.cz/qgisserver/cgi-bin/qgis_mapserv.fcgi?map=/opt/RVI4S1/dolni-ujezd/asahi-breweries/asahi-zddu.qgs",
        params: {
          LAYERS: "Dolní Újezd_ndvi_26-3-2022",
          INFO_FORMAT: undefined,
          FORMAT: "image/png; mode=8bit",
        },
        crossOrigin: "anonymous",
      }),
      visible: false,
    }),

    new Tile({
      properties: {
        title: "DÚ NDVI 10-05-2022",
        path: "Dolní Újezd",
      },
      source: new TileWMS({
        url: "https://gis.lesprojekt.cz/qgisserver/cgi-bin/qgis_mapserv.fcgi?map=/opt/RVI4S1/dolni-ujezd/asahi-breweries/asahi-zddu.qgs",
        params: {
          LAYERS: "Dolní Újezd_ndvi_10-5-2022",
          INFO_FORMAT: undefined,
          FORMAT: "image/png; mode=8bit",
        },
        crossOrigin: "anonymous",
      }),
      visible: false,
    }),

    new Tile({
      properties: {
        title: "Zony",
        path: "Dolní Újezd",
      },
      source: new TileWMS({
        url: "https://gis.lesprojekt.cz/qgisserver/cgi-bin/qgis_mapserv.fcgi?map=/opt/RVI4S1/dolni-ujezd/asahi-breweries/asahi-zddu.qgs",
        params: {
          LAYERS: "zony",
          INFO_FORMAT: undefined,
          FORMAT: "image/png; mode=8bit",
        },
        crossOrigin: "anonymous",
      }),
      visible: true,
    }),
  ],
};
*/

const geojsonFormat = new GeoJSON();

const rosteniceFields = new VectorLayer({
  properties: {
    title: "Rostenice LPIS",
    popUp: {
      attributes: ["*"],
    },
  },
  source: new VectorSource(),
});
export default {
  user_id: 14, //Needed for senslogOT
  group: "Kraus", //Needed for MapLogOT
  user: "mikr", //Needed for MapLogOT,
  viewCenter: [1873488.0178251606, 6285055.502574428],
  viewZoom: 15,
  title: "FIE20 - Rostenice",
  layers: [rosteniceFields],
};

fetch("./assets/rostenice_lpis_export.json")
  .then((response) => response.json())
  .then((data) => {
    rosteniceFields.getSource().addFeatures(
      geojsonFormat.readFeatures(data, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      })
    );
  });
