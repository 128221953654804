import './analysis/analysis.module';
import {Component} from '@angular/core';
import {FieAnalysisPanelComponent} from './analysis/analysis.component';
import {
  HsConfig,
  HsEventBusService,
  HsLanguageService,
  HsLayerEditorService,
  HsLayoutService,
  HsMapService,
  HsPanelContainerService,
  HsShareUrlService,
  HsSidebarService,
  HsUtilsService,
} from 'hslayers-ng';
import {HsSensorsService} from 'hslayers-sensors';
import {HttpClient} from '@angular/common/http';
import {MainService} from './main.service';
import {
  createTrackLayers,
  infrared,
  updateDimensionOptions,
} from './layers/layers';
import {meteoblueDimensionChanged} from './layers/meteo-layers';
import {take} from 'rxjs';
import {vegetationLayers} from './layers/vegetation-layers';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private HsLayoutService: HsLayoutService,
    private HsEventBusService: HsEventBusService,
    private HsSidebarService: HsSidebarService,
    private HsShareUrlService: HsShareUrlService,
    public HsLanguageService: HsLanguageService,
    private HsUtilsService: HsUtilsService,
    public MainService: MainService,
    private HsSensorsService: HsSensorsService,
    private HsPanelContainerService: HsPanelContainerService,
    private $http: HttpClient,
    private HsMapService: HsMapService,
    private HsLayerEditorService: HsLayerEditorService,
    private HsConfig: HsConfig
  ) {
    this.HsLayoutService.get().sidebarButtons = true;
    this.HsLayoutService.setDefaultPanel('layermanager');
    this.HsSidebarService.sidebarLoad.subscribe(() => {
      this.HsPanelContainerService.create(FieAnalysisPanelComponent, {});
    });

    this.HsEventBusService.layermanagerDimensionChanges.subscribe((args) => {
      meteoblueDimensionChanged(
        args,
        this.HsShareUrlService,
        this.HsUtilsService
      );
    });
    this.HsSidebarService.addButton({
      panel: 'analysis',
      order: -1,
      title: this.HsLanguageService.getTranslation('Analysis'),
      description: HsLanguageService.getTranslation('Run analysis'),
      icon: 'icon-alertpay',
    });

    this.HsSidebarService.get()
      .buttons.pipe(take(1))
      .subscribe((buttons) => {
        for (const but of [
          'legend',
          'composition_browser',
          'datasource_selector',
          'permalink',
          'info',
          'print',
          'saveMap',
        ]) {
          this.HsSidebarService.setButtonImportancy(buttons, but, false);
        }
        const sensorsButton = buttons.find((b) => b.panel == 'sensors');
        if (sensorsButton) {
          sensorsButton.order = -0.5;
        }
      });

    this.MainService.init();

    updateDimensionOptions(
      [...vegetationLayers, infrared],
      this.$http,
      this.HsUtilsService,
      this.HsEventBusService
    );
  }

  async ngOnInit(): Promise<void> {
    await this.HsMapService.loaded('default');
    if (this.HsConfig.get('default').senslog.user != 'mikr') {
      const trackLayers = await createTrackLayers(
        this.HsConfig.get('default').senslog.user
      );
      for (const lyr of trackLayers) {
        this.HsMapService.getMap().addLayer(lyr);
      }
    }
  }
}
