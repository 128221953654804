const _legends: any = {
  categs242: [
    { value: '1', color: 'rgba(117, 214, 132, 0.7)', title: 'Allowed' },
    { value: '0', color: 'rgba(211, 84, 84, 0.7)', title: 'Prohibited' },
  ],

  create242() {
    return `<svg  xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="150"
    height="80"
    viewBox="0 -3 33.229166 20.375002"
    version="1.1"
    inkscape:version="0.92.4 5da689c313, 2019-01-14"
    sodipodi:docname="legend.svg"> <g
    inkscape:label="Layer 1"
    inkscape:groupmode="layer"
    transform="translate(0,0)">
    ${_legends.categs242.map(i => ` <rect
        style="fill:${i.color};fill-opacity:1;stroke-width:0.26458332"
        width="3.96875"
        height="3.4017856"
        x="0"
        y="${_legends.categs242.indexOf(i) * 3.5127856}" />
     <text
        xml:space="preserve"
        style="font-style:normal;font-weight:normal;font-size:3.52777767px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
        x="5.1026788"
        y="221.78268"
        ><tspan
          sodipodi:role="line"
          x="${5.1026788 + (i.value < 0 ? 20 : 0)}"
          y="${2.8 + _legends.categs242.indexOf(i) * 3.5127856}"
          style="stroke-width:0.26458332"> <tspan
    style="font-size:2.82222223px"
    >${i.title}</tspan></tspan></text>`).join('\n')

      }
  
 </g>
</svg>`
  },
  
  categs245: [
    { value: 0.002, color: "rgba(0,125,235, 0.7)" },
    { value: 0.004, color: "rgba(0,235,219, 0.7)" },
    { value: 0.006, color: "rgba(0,235,94, 0.7)" },
    { value: 0.008, color: "rgba(31,235,0, 0.7)" },
    { value: 0.01, color: "rgba(156,235,0, 0.7)" },
    { value: 0.012, color: "rgba(235,188,0, 0.7)" },
    { value: 0.014, color: "rgba(235,62,0, 0.7)" },
  ],
  create245(){
    return `<svg  xmlns:dc="http://purl.org/dc/elements/1.1/"
  xmlns:cc="http://creativecommons.org/ns#"
  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
  xmlns:svg="http://www.w3.org/2000/svg"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
  xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
  width="150"
  height="110"
  viewBox="0 -3 33.229166 30"
  version="1.1"
  inkscape:version="0.92.4 5da689c313, 2019-01-14"
  sodipodi:docname="legend.svg"> <g
  inkscape:label="Layer 1"
  inkscape:groupmode="layer"
  transform="translate(0,0)">
  ${_legends.categs245.map(i => ` <rect
      style="fill:${i.color};fill-opacity:1;stroke-width:0.26458332"
      width="3.96875"
      height="3.4017856"
      x="${0.75}"
      y="${_legends.categs245.indexOf(i) * 3.5127856}" />
  <text
      xml:space="preserve"
      style="font-style:normal;font-weight:normal;font-size:3.52777767px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
      x="5.1026788"
      y="221.78268"
      ><tspan
        sodipodi:role="line"
        x="${5.1026788}"
        y="${2.7 + _legends.categs245.indexOf(i) * 3.5127856}"
        style="stroke-width:0.26458332"> <tspan
  style="font-size:2.82222223px"
  >${i.value}</tspan></tspan></text>`).join('\n')

}
<text
xml:space="preserve"
style="font-style:normal;font-weight:normal;font-size:3.12777767px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
x="25.1339285"
y="23.97617"
id="text4566"><tspan
sodipodi:role="line"
id="tspan4564"
x="25.1339285"
y="23.97617"
style="stroke-width:0.26458332">(kg/m2)</tspan></text>
</g>
</svg>`;
},

categs246: [
  { value: -0.100, color: "rgba(0,0,235, 0.7)" },
  { value: -0.072, color: "rgba(0,156,235, 0.7)" },
  { value: -0.043, color: "rgba(0,235,156, 0.7)" },
  { value: -0.015, color: "rgba(0,235,0, 0.7)" },
  { value: 0.013, color: "rgba(156,235,0, 0.7)" },
  { value: 0.042, color: "rgba(235,156,0, 0.7)" },
  { value: 0.070, color: "rgba(235,0,0, 0.7)" },
],
create246(){
  return `<svg  xmlns:dc="http://purl.org/dc/elements/1.1/"
xmlns:cc="http://creativecommons.org/ns#"
xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
xmlns:svg="http://www.w3.org/2000/svg"
xmlns="http://www.w3.org/2000/svg"
xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
width="150"
height="110"
viewBox="0 -3 33.229166 30"
version="1.1"
inkscape:version="0.92.4 5da689c313, 2019-01-14"
sodipodi:docname="legend.svg"> <g
inkscape:label="Layer 1"
inkscape:groupmode="layer"
transform="translate(0,0)">
${_legends.categs246.map(i => ` <rect
    style="fill:${i.color};fill-opacity:1;stroke-width:0.26458332"
    width="3.96875"
    height="3.4017856"
    x="${0.75}"
    y="${_legends.categs246.indexOf(i) * 3.5127856}" />
<text
    xml:space="preserve"
    style="font-style:normal;font-weight:normal;font-size:3.52777767px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
    x="5.1026788"
    y="221.78268"
    ><tspan
      sodipodi:role="line"
      x="${5.1026788}"
      y="${2.7 + _legends.categs246.indexOf(i) * 3.5127856}"
      style="stroke-width:0.26458332"> <tspan
style="font-size:2.82222223px"
>${i.value}</tspan></tspan></text>`).join('\n')

}
<text
xml:space="preserve"
style="font-style:normal;font-weight:normal;font-size:3.12777767px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.26458332"
x="25.1339285"
y="23.97617"
id="text4566"><tspan
sodipodi:role="line"
id="tspan4564"
x="25.1339285"
y="23.97617"
style="stroke-width:0.26458332"></tspan></text>
</g>
</svg>`;
}
};

_legends.legend242 = _legends.create242();
_legends.legend245 = _legends.create245();
_legends.legend246 = _legends.create246();

export const Legends = _legends;