import VectorLayer from 'ol/layer/Vector';
import { GeoJSON } from 'ol/format';
import { Vector as VectorSource } from 'ol/source';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HsQueryVectorService, HsMapService, HsUtilsService, HsLayerShiftingService } from 'hslayers-ng';
import * as moment from 'moment';

const format = new GeoJSON();

@Injectable({
    providedIn: 'root',
})

export class FieAnalysisService {
    resultsPolygonLayer = new VectorLayer({
        properties: {
            title: 'Analysis results',
            path: 'User generated',
            popUp: {
                attributes: ['value', 'raster_val'],
            },
        },
        
        
        source: new VectorSource({}),
    });
    constructor(
        public HsUtilsService: HsUtilsService,
        private $http: HttpClient,
        public HsMapService: HsMapService,
        public HsQueryVectorService: HsQueryVectorService,
        private HsLayermanagerPhysicalListService: HsLayerShiftingService) {

    }

    async getGeonameAtCoordinate(coordinate): Promise<string> {
        var point = {
            lon: coordinate[0].toFixed(2),
            lat: coordinate[1].toFixed(2)
        };
        const r:any = await this.$http.get(this.HsUtilsService.proxify(`http://api.geonames.org/findNearbyPlaceNameJSON?lat=${point.lat}&lng=${point.lon}&username=raitis`))
                .toPromise();
        return r.geonames[0].name        
    }

    async getMeteoblueResults(p: number[], service: string, placeName: string, analysis: any): Promise<string> {
        const data: any = {
            lat: p[1],
            lon: p[0],
            asl: 258,
            tz: 'Europe/Athens',
            city: placeName || ''
        };
        if (service == 'meteogram_agroSowing') data.look = 'all';
        switch(analysis.provider){
            case 'meteoblue-image':
                var params = Object.keys(data).map(function (k) {
                    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
                }).join('&');
                return this.HsUtilsService.proxify(`http://my.meteoblue.com/visimage/${service}?${params}`, undefined, false);
            case 'meteoblue-image-pyapi':
            case 'meteoblue-iframe-pyapi':
                let url = analysis.url;
                for(const key of Object.keys(analysis.parameters)){
                    const param = analysis.parameters[key];
                    data[key] = param.value;
                }
                for(const key of Object.keys(data)){
                    url = url.replace('${' + key + '}', data[key]);
                }
                return this.HsUtilsService.proxify(url);
        }
    }

    serializeFeature(f) {
        const geojson = format.writeFeaturesObject([f], {
            dataProjection: 'EPSG:4326',
            featureProjection: this.HsMapService.getMap().getView().getProjection().getCode()
        });
        geojson.crs = {
            type: "name",
            properties: {
                name: 'EPSG:4326'
            }
        }
        return geojson;
    }

    getAllFeatures(layer) {
        return layer.getSource().getFeatures().filter(f =>
            f.getGeometry() &&
            ['Polygon', 'MultiPolygon'].indexOf(f.getGeometry().getType()) > -1);
    }

    runLesprojektAnalysis({ url, layer, style, parameters, name, featureGroup }) {
        const src = this.resultsPolygonLayer.getSource();
        src.clear();
        let features;
        if (featureGroup == 'all') {
            features = this.getAllFeatures(layer);
        } else if (featureGroup == 'selected') {
            features = this.HsQueryVectorService.get().selector.getFeatures().getArray()
        }
        if (features.length == 0) {
            if (this.getAllFeatures(layer).length < 10) {
                features = this.getAllFeatures(layer);
            } else {
                alert('Please select features on the map');
            }
        }
        features.forEach(async (f) => {
            const geojson = this.serializeFeature(f);
            let postedParams: any = {};
            switch (name) {
                case 'protected-areas':
                    postedParams.offset = parseInt(parameters.offset.value);    
                    postedParams.geometries = geojson
                    break;
                case 'fertilization-map':
                    postedParams.fertilizationMethod = parameters.fertilizationMethod.value;
                    postedParams.fertilizerVolume = parseInt(parameters.fertilizerVolume.value);
                    postedParams.areaOfInterest = geojson;
                    break;
                case 'ndvi-average-daily-change':
                    postedParams.date = this.convertToMoment(parameters.date.value).format('YYYY-MM-DD');
                    postedParams.aoi = geojson;
                    break;
            }
            const response:any = await this.$http.post(this.HsUtilsService.proxify(url),
                postedParams
            ).toPromise();

            src.addFeatures(format.readFeatures(response, {
                featureProjection: this.HsMapService.getMap().getView().getProjection().getCode()
            }));
            this.resultsPolygonLayer.setStyle(style);
            if (this.HsMapService.getLayersArray().indexOf(this.resultsPolygonLayer) == -1)
            this.HsMapService.getMap().addLayer(this.resultsPolygonLayer);
            
            this.HsLayermanagerPhysicalListService.moveToTop(this.resultsPolygonLayer, 'default');
        })
    }

    private convertToMoment(model: any) {
        const momentTime = moment(model);
        if (model != undefined) {
          if (
            model.year &&
            model.month &&
            model.day
          ) {
            return momentTime.subtract(1, 'month');
          } else {
            return momentTime;
          }
        }
    }
    

    async executeNdviStats(feature) {
        const geojson = this.serializeFeature(feature);
        let postedParams = geojson;
        try {
            const response = await this.$http.post(this.HsUtilsService.proxify('https://gis.lesprojekt.cz/smartagrihubs/analysis-2.4.3'),
                postedParams
            ).toPromise();
            return response;
        } catch (ex) {
            throw ex
        }
    }
}
