import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import genLabelStyle from './gen-label-style';
import {Circle, Fill, Stroke, Style} from 'ol/style';
import {HttpClient} from '@angular/common/http';
import {Point} from 'ol/geom';
import {Tile} from 'ol/layer';
import {TileWMS, XYZ} from 'ol/source';
import {Vector as VectorSource} from 'ol/source';

const labelStyle = genLabelStyle;
const assetsPath = (<any>window).assetsPath
  ? (<any>window).assetsPath
  : window.location.hostname.indexOf('local.groundwater') > -1
  ? `assets/`
  : 'static/assets/';

export const vegetationLayers = [
  new Tile({
    properties: {
      title: 'NDVI index (Vilcini)',
      from_composition: true,
      dimensions: {
        time: {
          value: '2020-11-20',
          name: 'time',
          values: ['2020-11-20'],
        },
      },
      path: 'Vegetation indexes and satellite imagery',
      inlineLegend: true,
      legendImage: assetsPath + 'images/NDVI_legend.png',
      editor: {editable: false},
    },
    source: new XYZ({
      attributions:
        '&copy; <a href="http://www.baltsat.lv/">Baltic Satellite Service</a>, <a href="https://www.esa.int/">European Space Agency - ESA</a>',
      url: 'https://wms.forestradar.com/tiles-v1/fie-xNFwHfJdIR1dCtA7kJ1K8g/{time}-NDVI/{z}/{x}/{y}.png',
    }),
    maxZoom: 18,
    visible: false,
    opacity: 1,
  }),
  new Tile({
    properties: {
      title: 'MSAVI2 index (Vilcini)',
      from_composition: true,
      dimensions: {
        time: {
          value: '2020-11-20',
          name: 'time',
          values: ['2020-11-20'],
        },
      },
      path: 'Vegetation indexes and satellite imagery',
      inlineLegend: true,
      legendImage: assetsPath + 'images/NDVI_legend.png',
      editor: {editable: false},
    },
    source: new XYZ({
      attributions:
        '&copy; <a href="http://www.baltsat.lv/">Baltic Satellite Service</a>, <a href="https://www.esa.int/">European Space Agency - ESA</a>',
      url: 'https://wms.forestradar.com/tiles-v1/fie-xNFwHfJdIR1dCtA7kJ1K8g/{time}-MSAVI2/{z}/{x}/{y}.png',
    }),
    maxZoom: 18,
    visible: false,
    opacity: 1,
  }),
  new Tile({
    properties: {
      title: 'Yield productivity zones',
      abstract: 'yieldLayerAbstract',
      from_composition: true,
      queryCapabilities: false,
      path: 'Vegetation indexes and satellite imagery',
      legendImage: assetsPath + 'images/yield.png',
      inlineLegend: true,
    },
    source: new TileWMS({
      url: 'http://51.15.45.95:8080/geoserver/fie20/wms',
      params: {
        LAYERS: 'fie20:yp_vilcini',
        INFO_FORMAT: undefined,
        FORMAT: 'image/png; mode=8bit',
      },
      crossOrigin: 'anonymous',
    }),
    visible: false,
  }),
];

export const vegetationLayersLesprojekt = [
 /*  new Tile({
    properties: {
      title: 'NDWI (green band)',
      abstract: '',
      from_composition: true,
      queryCapabilities: true,
      path: 'Vegetation indexes and satellite imagery',
      inlineLegend: true,
    },
    source: new TileWMS({
      url: 'https://gis.lesprojekt.cz/geoserver/S2_2020/wms',
      params: {
        LAYERS: '34VFH_NDWI',
        INFO_FORMAT: undefined,
        FORMAT: 'image/png; mode=8bit',
      },
      crossOrigin: 'anonymous',
    }),

    visible: false,
  }),

  new Tile({
    properties: {
      title: 'NDWI (SWIR band)',
      abstract: '',
      from_composition: true,
      queryCapabilities: true,
      path: 'Vegetation indexes and satellite imagery',
      inlineLegend: true,
    },
    source: new TileWMS({
      url: 'https://gis.lesprojekt.cz/geoserver/S2_2020/wms',
      params: {
        LAYERS: '34VFH_NDWI_SWIR',
        INFO_FORMAT: undefined,
        FORMAT: 'image/png; mode=8bit',
      },
      crossOrigin: 'anonymous',
    }),
    visible: false,
  }), */
];

const ndviStyle = [
  new Style({
    image: new Circle({
      radius: 5,
      stroke: new Stroke({
        color: '#fff',
      }),
      fill: new Fill({
        color: '#3399CC',
      }),
    }),
  }),
  labelStyle,
];
